$(document).ready(function(){

    bookingPanel.Init();
    
    var bookingWrapper = $(".bookingContent").length ? $(".bookingContent") : $("#bookingSection");


    var scheduleNavLinkHandler = function(e){

        var action = $(this).data('url');

        if (typeof action === 'undefined') {
            return;
        }

        if(!bookingWrapper.find('.loader').length){
            var $clone = $("#loader").clone();
            $clone.removeAttr('id').addClass('loader');
            $clone.css('position', 'absolute');
            $clone.css('opacity', '0.9');
            $clone.css('z-index', '999');
            $clone.css('display', 'none');
            bookingWrapper.find('.container').append($clone);
            bookingWrapper.find('.container').css('min-height', bookingWrapper.find('.container').height());
        }

        $.ajax({
            type: 'POST',
            url: action,
            dataType: 'json',
            processData: false,
            beforeSend: function(){
                $('.loader').fadeIn(100)
            },
            contentType: false,
            statusCode: {
                200: function (response) {
                    var $currentWeek = $('.bookingWrapper');
                    $currentWeek.remove();
                    bookingWrapper.find('.container').append(response.html);
                    $('.loader').fadeOut(500);
                },
                400: function (jqXHR) {
                    window.showMessage(jqXHR.responseJSON);

                }
            }
        });
    };

    // lapozás
    $(document).on('click', '.scheduleNavLink', scheduleNavLinkHandler);
});

$(window).resize(function () {
    bookingPanel.Resize();
});


var bookingPanel = function (window, document, $)
{

    var Init = function () {
        bookingCollapse();
    }

    var Resize = function () {

    }

    var bookingCollapse = function () {

        $(document).on('click', '.dayItem:not(.disabled) > a', function () {

            var parent = $(this).parent();
            var opened  = parent.children("div").hasClass("opened");

            $(".dayItem").removeClass("active");

            $(".bookingBox").find(".opened").slideToggle(300).removeClass("opened");

            if (opened == false){
                parent.addClass("active").children("div").addClass("opened").delay(300).slideToggle(300, function(){
                    animate(parent.offset().top - 100);
                });
            }

        });

    }

    var animate = function(posY){
        $('html, body').animate({
            scrollTop: posY
        }, 300);
    }

    return {
        Init: Init,
        Resize: Resize
    }

}(window, document, $);
