$(document).ready(function(){

    errorPage.Init();

});

$(window).resize(function () {
    errorPage.Resize();
});

var errorPage = function (window, document, $)
{

    var Init = function () {
        pageSizer();
    }

    var Resize = function () {
        pageSizer();
    }

    var pageSizer = function () {
        var newHeight;
        if($(window).height() < 750) {
            newHeight = $(window).height();
            $(".errorContent").height(newHeight);
        }
        else {
            newHeight = $(window).height() - $(".mainFooter").innerHeight();
            $(".errorContent").innerHeight(newHeight);
        }

    }


    return {
        Init: Init,
        Resize: Resize
    }

}(window, document, $);



