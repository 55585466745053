$(function () {
    function socialLogin(network) {
        hello(network).login().then(function () {
            hello(network).api('me').then(function (r) {
                var id = r.id;
                var email = r.email;
                var first_name = r.first_name;
                var last_name = r.last_name;
                var picture = r.picture;

                var data = {
                    network: network,
                    oauthid: id,
                    email: email,
                    regFirstName: first_name,
                    regLastName: last_name,
                    regPicture: picture,
                    regSubmit: true
                };

                $.ajax({
                    type: 'POST',
                    url: window.serverVariables.registerAction, //$("#koszfitModal").find('form').attr('action') || window.serverVariables.registerAction,
                    dataType: 'json',
                    data: data,
                    success: function (data) {
                        if (data.status === 'success') {
                            window.location.reload();
                        }
                    }
                });
            }, function (e) {
                $('#loginModal').modal('hide');
                $('#koszfitModal').modal('hide');
                $('#registerModal').modal('hide');
                $('#socialErrorModalWrapper').modal().on('shown.bs.modal', function(){
                    $(this).find('p.modalInfo').text($(this).attr('data-login-error'))
                });
            });
        }, function (e) {
            $('#loginModal').modal('hide');
            $('#registerModal').modal('hide');
            $('#koszfitModal').modal('hide');
            $('#socialErrorModalWrapper').modal().on('shown.bs.modal', function(){
                $(this).find('p.modalInfo').text($(this).attr('data-socialdata-error'))
            });
        });
    }

    $(document).on('click', '.fbBtn', function () {
        socialLogin('facebook');
    });

    $(document).on('click', '.googlePlusBtn', function () {
        socialLogin('google');
    });

    hello.init({
        facebook: window.serverVariables.facebookToken,
        google: window.serverVariables.googleToken
    }, {redirect_uri: window.location.origin + '/themes/static/html/social/redirect.html', scope: 'basic, email'});
});
