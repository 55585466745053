
$(document).ready(function(){

    Main.Init();

    $("#loader").fadeOut(1500);

});

$(window).resize(function () {
    Main.Resize();
});

var Main = function (window, document, $)
{

    var Init = function () {
        browserListener();
        cookiesBox();
        dropdownMenuOpen();
        langMenuOpen();
        menuOpen();
        mobileMenuClose();
        youtubeBox();
        modalEvents();
        shareFunctions();
        inputFocus();
    }

    var Resize = function () {
        mobileMenuRespoListener();
        langMenuOpen();
    }

    var browserListener = function(){
        var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 || !isChrome && !isOpera && window.webkitAudioContext !== undefined;
        var isIE = (navigator.userAgent.indexOf("Trident/7.0") > -1) ? true : false;
        var isEdge = navigator.userAgent.indexOf('Edge') >= 0;
        var isChrome = !!window.chrome && !isOpera && !isEdge;


        if (isOpera) {$("body").addClass("opera");}
        if (isFirefox) {$("body").addClass("firefox");}
        if (isSafari) {$("body").addClass("safari");}
        if (isChrome) {$("body").addClass("chrome");}
        if (isIE) {$("body").addClass("explorer");}
        if (isEdge) {$("body").addClass("edge");}

    }

    var cookiesBox = function() {
        var cookiesP = $("#cookiesP").html();
        var cookiesOk = $("#cookiesOk").html();
        var cookiesDetails = $("#cookiesDetails").html();
        var cookiesLink = $("#cookiesDetails").attr("href");

        window.addEventListener("load", function(){
            window.cookieconsent.initialise({
                "palette": {
                    "popup": {
                        "background": "#101c30"
                    },
                    "button": {
                        "background": "#4ae441",
                        "text": "#ffffff"
                    }
                },
                "position": "bottom-right",
                "content": {
                    "message": cookiesP,
                    "dismiss": cookiesOk,
                    "link": cookiesDetails,
                    "href": cookiesLink
                }
            })});

    }
    
    var dropdownMenuOpen = function () {


        $(".mainHeader .menuBox li").hover(function () {
            if($(window).width() > 1299){
                if($(this).find("ul").length > 0){
                    $(this).find("ul").fadeIn(300);
                    $(this).addClass("opened");
                }
            }

        },function () {
            if($(window).width() > 1299){
                $(this).find("ul").fadeOut(300);
                $(this).removeClass("opened");
            }
        })

        $(".mainHeader .menuBox li").on("click", function () {
            if($(window).width() < 1300) {
                if ($(this).find("ul").length > 0) {
                    $(this).find("ul").slideToggle(300, function () {
                        var menuHeight = $(".mainHeader .menuWrapper").innerHeight();
                        if(menuHeight > $(window).height()){
                            $(".mainHeader").css({
                                "height" : "100%",
                                "overflow-y" : "scroll",
                                "-webkit-overflow-scrolling" : "touch"
                            });
                        }
                        else {
                            $(".mainHeader").removeAttr("style");
                        }
                    });
                    if ($(this).hasClass("opened")) {
                        $(this).removeClass("opened");
                    }
                    else {
                        $(this).addClass("opened");
                    }
                }
            }
        });
    }

    var langMenuOpen = function () {
        if($(window).width() > 1299) {
            $(".langMenu > li").hover(function () {
                $(".langMenu").find("ul").fadeIn(200);
            }, function () {
                $(".langMenu").find("ul").fadeOut(200);
            });
        }         else {
            $(".langMenu > li").off();
        }
    }


    var menuOpen = function(){
        var menuHeight;
        $(".menuOpen").on("click", function(){
            if($("body").hasClass("menuOn")){
                $(".mainHeader").removeAttr("style");
                $(".menuWrapper").fadeOut(300);
                $(".mobileMenuClose").fadeOut(300);
                $("body").removeClass("menuOn");
            }
            else {

                $(".menuWrapper").fadeIn(300);
                $(".mobileMenuClose").fadeIn(300);

                menuHeight = $(".mainHeader .menuWrapper").innerHeight();
                if(menuHeight > $(window).height()){
                    $(".mainHeader").css({
                        "height" : "100%",
                        "overflow-y" : "scroll",
                        "-webkit-overflow-scrolling" : "touch"
                    });
                }
                $("body").addClass("menuOn");
            }

        });
    }


    var mobileMenuClose = function(){
        $(".mobileMenuClose").on("click",function(){
            $("body").toggleClass("menuOn");
            $(".menuWrapper").fadeToggle(300);
            $(".mobileMenuClose").fadeToggle(300);
        }); 
    }

    var mobileMenuRespoListener = function () {
        if($(window).width() > 1299){
            $("body").removeClass("menuOn");
            $(".menuWrapper, .mobileMenuClose, .mainHeader").removeAttr("style");
        };
    }

    var youtubeBox = function(){
        if ($(".mainTextbox iframe").length > 0) {
            $(".mainTextbox iframe").each(function(){
                if ($(this).attr("src").match("youtube")) {
                    $(this).wrap("<div class='youtubeBox'></div>");
                }
            });
        }
    }

    var modalEvents = function(){

        $('body').on('click', 'a[data-toggle="modal"]:not(.itsFull)', function(e) {

            e.preventDefault();

            var url = $(this).attr('href');
            var visibleModal = $('.modal.in');

            if (visibleModal.length > 0) {
                $.get(url).success(function(data) {
                    visibleModal.find(".modal-dialog").remove();
                    data = data.html || data;
                    visibleModal.html(data);
                });

                // csak a foglalásnál kell ez a fícsör
                if (visibleModal.children().hasClass('refreshAfterHide')) {
                    visibleModal.modal().on('hidden.bs.modal', function() {
                         window.location.reload();
                    });
                }
            }
            else {
                $.get(url).success(function(data) {
                    data = data.html || data;
                    $('<div id="koszfitModal" class="modal fade">' + data + '</div>').modal()
                        .on('hidden.bs.modal', function () {
                            // csak a foglalásnál kell ez a fícsör
                            if ($(this).children().hasClass('refreshAfterHide')) {
                                window.location.reload();
                            }
                            $(this).remove();

                        }).on('shown.bs.modal', function () {

                    });
                });
            }
        });

    }
    
    var successSubmit = function () {

        $(".requestBox").fadeOut(500);
        setTimeout(function(){
            $(".successRequestBox").fadeIn(500);
        },500);

    }

    var shareFunctions = function () {

        $(".shareBox .socialMenu a").on("click", function(){
            var window_size = "width=585,height=511";
            var url = this.href;
            var domain = url.split("/")[2];
            switch(domain) {
                case "www.facebook.com":
                    window_size = "width=585,height=368";
                    break;
                case "www.twitter.com":
                    window_size = "width=585,height=261";
                    break;
                case "plus.google.com":
                    window_size = "width=517,height=511";
                    break;
            }
            window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,' + window_size);
            return false;
        });
    }

    var inputFocus = function(){

        var value;
        var parent;
        var wasError;

        $(":input:not([type='checkbox']):not([type='radio']):not([type='submit']):not(select)").each(function(){
            if ( !!$(this).val() ) {
                $(this).parents(".inputBox").addClass("hasValue");
            }
        });

        $("body").on("focus", ":input:not([type='checkbox']):not([type='radio']):not([type='submit'])", function(){
            wasError = false;
            parent = $(this).parents(".inputBox");
            if (parent.hasClass("error")) {
                value = $(this).val();
                wasError = true;
            }
            parent.addClass("focused").removeClass("error").find(".errorMsg").fadeOut(200);
        }).on("blur", ":input:not([type='checkbox']):not([type='radio']):not([type='submit'])", function(){
            if (wasError && value === $(this).val()) {
                parent.addClass("error").find(".errorMsg").fadeIn(200);
            }
            if ( !!$(this).val() ) {
                parent.removeClass("focused").addClass("hasValue");
            }
            else {
                parent.removeClass("focused").removeClass("selected").removeClass("hasValue");
            }
        });

    }

    return {
        Init: Init,
        Resize: Resize,
        InputFocus: inputFocus
    }

}(window, document, $);