$(document).ready(function(){

    FaqPanel.Init();

});

$(window).resize(function () {
    FaqPanel.Resize();
});

var FaqPanel = function (window, document, $)
{

    var Init = function () {
       faqCollapse();
    }

    var Resize = function () {

    }

    var faqCollapse = function () {

        $(document).on('click', '.faqItem > a', function () {

            var parent = $(this).parent();
            var opened  = parent.children("div").hasClass("opened");

            $(".faqItem").removeClass("active");

            $(".faqBox").find(".opened").slideToggle(300).removeClass("opened");

            if (opened == false){
                parent.addClass("active").children("div").addClass("opened").delay(300).slideToggle(300, function(){
                    animate(parent.offset().top - 100);
                });
            }

        });

    }

    var animate = function(posY){
        $('html, body').animate({
            scrollTop: posY
        }, 300);
    }

    return {
        Init: Init,
        Resize: Resize
    }

}(window, document, $);



