$(document).ready(function(){

    if ($('#mapBox').length) {
        Contact.Init();
    }
});

$(window).resize(function () {
    Contact.Resize();
});

var Contact = function (window, document, $)
{

    var Init = function () {
        contactSelect();
        mapInit();
    }

    var Resize = function () {
        mapSizer();
    }

    var contactSelect = function(){
        if ($(".contactFormBox select").length > 0) {
            $(".contactFormBox select").each(function(){
                var currentId = $(this).attr('id');
                var currentName = $(this).attr('name');
                var currentValue = $(this).val();
                $(this).editableSelect({
                    filter: false,
                    effects: 'fade',
                    duration: 300
                }).on('select.editable-select', function (e, li) {
                    $('input[name="' + currentName + '"][type="text"]').removeAttr('id');
                    if(!$('input[name="' + currentName + '"][type="hidden"]').length){
                        var hiddenInputValue = document.createElement('input');
                        hiddenInputValue.setAttribute('type', 'hidden');
                        hiddenInputValue.setAttribute('id', currentId);
                        hiddenInputValue.setAttribute('name', currentName);
                        $(hiddenInputValue).insertAfter($('input[name="' + currentName + '"][type="text"]'));
                        $('input[name="' + currentName + '"][type="text"]').removeAttr('name').attr('readonly', 'readonly');
                    }
                    $('input#' + currentId + '[type="hidden"]').val(li ? li.attr('value') : currentValue);
                }).trigger('select.editable-select');
            });
        }
    }

    var mapSizer = function () {
        if($(window).width() > 1299){
            var height = $(".contactContent .leftBox").innerHeight();
            $("#mapBox").innerHeight(height);
        }
        else {
            $("#mapBox").css("height","");
        }
    }

    var map = function(){

        var drag = true;
        var clickZoom = true;
        var scrollwheel = false;
        var wW = 0;
        wW = $(window).width();

        if (Modernizr.touch && wW > 1023) {
            drag = true;
            clickZoom = true;
        }

        if (wW < 1000) {
            scrollwheel = false;
            clickZoom = true;
        }

        var latlng = new google.maps.LatLng(46.0727537, 18.2112553);
        directionsDisplay = new google.maps.DirectionsRenderer();
        var settings = {
            zoom: 17,
            center: latlng,
            scrollwheel: scrollwheel,
            mapTypeControl: false,
            fullscreenControl: false,
            panControl: false,
            //mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
            navigationControl: false,
            //navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
            streetViewControl: false,
            draggable: drag,
            disableDoubleClickZoom: clickZoom,
            zoomControl: false,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.LEFT_TOP
            },

            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
                {
                    "featureType": "all",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        },
                        {
                            "saturation": "-100"
                        },
                        {
                            "lightness": "30"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        },
                        {
                            "gamma": "0.00"
                        },
                        {
                            "lightness": "74"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#1a2639"
                        }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "all",
                    "stylers": [
                        {
                            "lightness": "3"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#162235"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#2d2c45"
                        },
                        {
                            "lightness": "0"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#7d7c9b"
                        },
                        {
                            "lightness": "43"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#182437"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#7d7c9b"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#152134"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "hue": "#ff0000"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#7d7c9b"
                        },
                        {
                            "lightness": "-31"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#2d2c45"
                        },
                        {
                            "lightness": "-36"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#2d2c45"
                        },
                        {
                            "lightness": "0"
                        },
                        {
                            "gamma": "1"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        };
        var map = new google.maps.Map(document.getElementById("mapBox"), settings);
        directionsDisplay.setMap(map);

        var companyLogo = new google.maps.MarkerImage("/themes/koszfit/img/marker.png",

            new google.maps.Size(60,60),
            new google.maps.Point(0,0),
            new google.maps.Point(30,30)
        );
        var companyPos = new google.maps.LatLng(46.0727537, 18.2112553);
        var companyMarker = new google.maps.Marker({
            position: companyPos,
            map: map,
            icon: companyLogo,
            title: "Kószfit Crossgym"
        });

    }

    var mapInit = function(){
        mapSizer();
        map();
        google.maps.event.addDomListener(window, 'resize', map);
    }


    return {
        Init: Init,
        Resize: Resize,
        ContactSelect: contactSelect
    }

}(window, document, $);




