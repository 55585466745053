$(document).ready(function(){

    Profile.Init();

});

$(window).resize(function () {
    Profile.Resize();
});

var Profile = function (window, document, $)
{

    var Resize = function () {

    }

    var dateMask = function () {
        $('.date').mask('0000/00/00');
    }

    var profileImgHandler = function(){

        var $profileImgForm = $('#profileImgForm');
        var $profileImgFormImageElem = $('#profileImgForm').find('input[type="file"][name="image"]');
        var $profileImgFormPreviewFigure = $('.profileImgBox').find('figure');
        var $profileImgFormPreviewImage = $profileImgFormPreviewFigure.find('img');
        $profileImgFormPreviewImage.data('original-image', $profileImgFormPreviewImage.attr('src'));

        var progress_bar_id = '#progress-wrp';

        this.readFile = function(){
            if (this.files && this.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $profileImgFormPreviewImage.attr('src', e.target.result);
                    $('.loginBox').find('img').attr('src', e.target.result);
                };
                reader.readAsDataURL(this.files[0]);
            }
        };

        this.hideLoader = function(){
            setTimeout(function(){
                $profileImgFormPreviewFigure.find('#progress-wrp').hide(300);
            },500);
        };

        this.showLoader = function(){
            if(!$profileImgFormPreviewFigure.find('#progress-wrp').length){
                $profileImgFormPreviewFigure.append('<div id="progress-wrp"><div class="progress-bar"><div class="status"></div></div></div>');
            } else {
                $profileImgFormPreviewFigure.find('#progress-wrp').show();
            }
        };

        this.uploadFile = function(){
            $.ajax({
                url : $profileImgForm.attr('action'),
                type: "POST",
                data : new FormData($profileImgForm[0]),
                contentType: false,
                dataType: 'json',
                cache: false,
                processData:false,
                xhr: function(){
                    //upload Progress
                    var xhr = $.ajaxSettings.xhr();
                    if (xhr.upload) {
                        xhr.upload.addEventListener('progress', function(event) {
                            var percent = 0;
                            var position = event.loaded || event.position;
                            var total = event.total;
                            if (event.lengthComputable) {
                                percent = Math.ceil(position / total * 100);
                            }
                            //update progressbar
                            $(progress_bar_id +" .progress-bar").css("width", + percent +"%");
                            $(progress_bar_id + " .status").text(percent +"%");
                        }, true);
                    }
                    return xhr;
                },
                statusCode: {
                    200: function(response){
                        $profileImgForm[0].reset(); //reset form
                        self.hideLoader();
                        showMessage(response);
                    },
                    400: function (jqXHR) {
                        self.hideLoader();
                        $profileImgFormPreviewImage.attr('src', $profileImgFormPreviewImage.data('original-image'));
                        $('.loginBox').find('img').attr('src', $profileImgFormPreviewImage.data('original-image'));
                        showMessage(jqXHR.responseJSON);
                    }
                },
                mimeType:"multipart/form-data"
            })
        };

        var self = this;

        $profileImgFormPreviewFigure.css('position', 'relative');

        this.showLoader();

        $profileImgFormPreviewImage.css({
            'position': 'absolute',
            'top': 0,
            'left': 0,
            'z-index': 1
        });

        $profileImgFormImageElem.on('change', function(){
            self.readFile.call(this);
            self.uploadFile();
        });

        $('.profileImgBox a').on('click', function() {
            $.ajax({
                url: $profileImgForm.attr('action'),
                type: "POST",
                data: {
                    'deleteProfileImage': true
                },
                dataType: 'json',
                statusCode: {
                    200: function (response) {
                        $profileImgForm[0].reset(); //reset form
                        self.hideLoader();
                        $profileImgFormPreviewImage.attr('src', '/themes/koszfit/img/profileIcon.svg');
                        $('.loginBox').find('img').attr('src', '/themes/koszfit/img/profileIcon.svg');
                        showMessage(response);
                    },
                    400: function (jqXHR) {
                        self.hideLoader();
                        showMessage(jqXHR.responseJSON)
                    }
                }

            });
        });

    };

    var profileTab = function() {

        var index;

        $(".profileWrapper .tabHead a").on("click", function(){
            index = $(this).index();
            $(".profileWrapper .tabHead a").removeClass("active");
            $(this).addClass("active");
            $(".profileWrapper .tabBody > div.active").removeClass("active").css("display","none");
            $(".profileWrapper .tabBody > div").eq(index).addClass("active").css("display","block");

        });

    };

    var profileSelect = function(){
        if ($(".profileWrapper select").length > 0) {
            $(".profileWrapper select").each(function(){
                var currentId = $(this).attr('id');
                var currentName = $(this).attr('name');
                var currentValue = $(this).val();
                $(this).editableSelect({
                    filter: false,
                    effects: 'fade',
                    duration: 300
                }).on('select.editable-select', function (e, li) {
                    $('input[name="' + currentName + '"][type="text"]').removeAttr('id');
                    if(!$('input[name="' + currentName + '"][type="hidden"]').length){
                        var hiddenInputValue = document.createElement('input');
                        hiddenInputValue.setAttribute('type', 'hidden');
                        hiddenInputValue.setAttribute('id', currentId);
                        hiddenInputValue.setAttribute('name', currentName);
                        $(hiddenInputValue).insertAfter($('input[name="' + currentName + '"][type="text"]'));
                        $('input[name="' + currentName + '"][type="text"]').removeAttr('name').attr('readonly', 'readonly');
                    }
                    $('input#' + currentId + '[type="hidden"]').val(li ? li.attr('value') : currentValue);
                }).trigger('select.editable-select');
            });
        }
    };

    var newsLetterCheckboxHandler = function(){
        var $checkbox = $('.inputBox.radioBox').find('input[type="checkbox"]');
        $checkbox.click(function(){
            $checkbox.prop('checked', false);
            $(this).prop('checked', true);
        });
    };

    var Init = function () {
        if($(window).width() > 767){
            profileTab();
        }
        profileSelect();
        dateMask();
        profileImgHandler();
        newsLetterCheckboxHandler();
    };

    return {
        Init: Init,
        Resize: Resize,
        ProfileSelect: profileSelect
    };

}(window, document, $);





