$(document).ready(function(){

    Home.Init();

});

$(window).resize(function () {
    Home.Resize();
});

var Home = function (window, document, $)
{

    var Init = function () {
        mainCaro();
        servicesScroll();
        servicesCaro();
    }

    var Resize = function () {

    }

    var mainCaro = function() {
        var loop = false;

        if ($("#mainCaro .caroItem").length > 1) {
            loop = true;
        }

        var owl = $("#mainCaro");

        owl.owlCarousel({
            loop: loop,
            dots: true,
            nav: false,
            /* navText: ["<i class='icon-arrowLeft'></i>","<i class='icon-arrowRight'></i>"],*/
            animateOut: 'fadeOut',
            autoplay: true,
            autoplayTimeout: 5000,
            mouseDrag: false,
            autoplayHoverPause: true,
            touchDraf: false,
            items: 1});
    }

    var servicesScroll = function () {

        $(".scrollToServices a").on("click",function () {

            var posServices = $("#servicesSection").offset().top;

            $('html, body').animate({
                scrollTop: posServices
            }, 500);

        });

    }

    var servicesCaro = function() {
        var loop = false;

        if ($(".servicesBox .servicesItem").length > 1) {
            loop = true;
        }

        var owl = $(".servicesBox");

        owl.owlCarousel({
            loop: loop,
            dots: false,
            nav: true,
            mouseDrag: false,
            touchDrag: true,
            navText: ["<i class='icon-arrowLeft'></i>","<i class='icon-arrowRight'></i>"],
            autoplay: false,
            autoplayTimeout: 5000,
            items: 1,
            responsive: {
                0: {
                    items: 1
                },
                1000: {
                    items: 2
                },
                1300: {
                    items: 3
                }
            }
        });

    }

    return {
        Init: Init,
        Resize: Resize
    }

}(window, document, $);

