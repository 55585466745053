$(document).ready(function(){
    PricesPanel.Init();
});

$(window).resize(function () {
    PricesPanel.Resize();
});


var PricesPanel = function (window, document, $)
{

    var Init = function () {
        pricesTab();
    }

    var Resize = function () {

    }

    var pricesTab = function() {

        var index;

        $(".pricesWrapper .tabHead a").on("click", function(){
            index = $(this).index();
            $(".pricesWrapper .tabHead a").removeClass("active");
            $(this).addClass("active");
            $(".pricesWrapper .tabBody > div.active").removeClass("active").css("display","none");
            $(".pricesWrapper .tabBody > div").eq(index).addClass("active").css("display","block");

        });

    }

    return {
        Init: Init,
        Resize: Resize
    }

}(window, document, $);
