$(document).ready(function(){
    QualificationPanel.Init();
});

$(window).resize(function () {
    QualificationPanel.Resize();
});

var QualificationPanel = function (window, document, $)
{

    var Init = function () {
        if($(".qualificationBox").hasClass("itsCaro")){
            qualificationCaro($(".qualificationBox"));
        }
        if($(".qualificationItem").hasClass("withGallery")){
            popupGallery($(".qualificationItem"));
        }
    }

    var Resize = function () {

    }

    var qualificationCaro = function($qualiPanel) {

        var loop = false;

        if ($qualiPanel.children().length > 1) {
            loop = true;
        }

        var owl = $qualiPanel;

        owl.owlCarousel({
            loop: loop,
            dots: false,
            nav: true,
            navText: ["<i class='icon-arrowLeft'></i>","<i class='icon-arrowRight'></i>"],
            autoplay: false,
            autoplayTimeout: 5000,
            items: 1,
            responsive: {
                320: {
                    items: 3,
                    margin: 20
                },
                600: {
                    items: 3,
                    margin: 60
                },
                601: {
                    items: 5,
                    margin: 60
                },
                1300: {
                    items: 3,
                    margin: 60
                }
            }
        });

    }

    var popupGallery = function($qualiPanel){

        $qualiPanel.on('click', function(){
            $(this).lightGallery({
                download: false,
                counter: false,
                loop: true,
                dynamic: true,
                dynamicEl: $(this).data("items").reverse()
            });
        });

    };

    return {
        Init: Init,
        Resize: Resize
    }

}(window, document, $);




