function handleFormResponse(data, $form) {
    var responseForm = data.form || data.results;
    if (responseForm) {
        $(responseForm).insertAfter($form);
        $form.remove();
        Main.InputFocus();
        Contact.ContactSelect();
        Profile.ProfileSelect();
        if(window.grecaptcha) {
            window.grecaptcha.render('g-recaptcha', {
                'sitekey' : $('#g-recaptcha').attr('data-sitekey')
            });
        }
    }
}

function enableDisableInputs(disable) {
    $(this).find(':input').attr('disabled', disable || false);
}

function showMessage(response) {
    if (response && response.hasOwnProperty('title') && response.hasOwnProperty('content')) {
        $('#loginModal').modal('hide');
        $('#registerModal').modal('hide');
        $('#koszfitModal').modal('hide');
        $('#successModalWrapper').modal()
            .on('shown.bs.modal', function () {
                $(".successRequestBox").fadeIn(200);
            });
        $('#successModalTitle').text(response.title);
        $('#successModalWrapper p.modalInfo').text(response.content);
    }
}
$(function () {
    $(document).on('submit', 'form', function (e) {
        e.preventDefault();
        var $form = $(this);
        var formData = new FormData(this);
        var action = $form.attr('action');
        var submit = $form.find(':submit');
        formData.append(submit.attr('name'), submit.val());

        // request
        $.ajax({
            type: 'POST',
            url: action,
            data: formData,
            dataType: 'json',
            processData: false,
            contentType: false,
            beforeSend: function () {
                enableDisableInputs.call($form, true);
            },
            statusCode: {
                200: function (response) {
                    handleFormResponse(response, $form);
                    enableDisableInputs.call($form, false);
                    showMessage(response);
                },
                302: function () {
                    window.location.reload();
                },
                201: function (response) {
                    handleFormResponse(response, $form);
                    enableDisableInputs.call($form, false);
                    showMessage(response);
                },
                400: function (jqXHR) {
                    handleFormResponse(jqXHR.responseJSON, $form);
                    enableDisableInputs.call($form, false);
                    showMessage(jqXHR.responseJSON);
                }
            }
        });
    });

});
