var $galleryPanel = $(".galleryPanel");

$(document).ready(function(){
    GalleryPanel.Init();
    $(window).trigger('resize');
});

$(window).resize(function () {
    GalleryPanel.Resize();
});

$(window).load(function () {
    if($galleryPanel.data("masonry")){
        GalleryPanel.masonry();
    }
});

var GalleryPanel = function (window, document, $)
{

    var Init = function () {
        popupGallery();
    }

    var popupGallery = function(){

        if($galleryPanel.data("folder")){

            $galleryPanel.children().click(function(){
                $(this).lightGallery({
                    download: false,
                    counter: false,
                    loop: true,
                    dynamic: true,
                    dynamicEl: $(this).data("items")
                });
            });

        }
        else {

            var array = $galleryPanel.data("items");
            var length = $galleryPanel.children().length;
            var j;

            for (var i = 0; i < length ; i++) {
                //j = i + 1;
                $galleryPanel.children().eq(i).attr("href", array[i]["src"]);
            }

            $galleryPanel.lightGallery({
                download: false,
                counter: false,
                loop: true
            });

        }

    }

    var masonry = function(){
        var children = $galleryPanel.children()["selector"];
        $galleryPanel.isotope({
            itemSelector: children,
            masonry: {
                columnWidth: children,
                gutter: 0,
                horizontalOrder: true
            }
        });

    }

    var Resize = function () {

    }

    return {
        Init: Init,
        Resize: Resize,
        masonry: masonry
    }

}(window, document, $);
